import { Typography, TypographyProps } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export interface InfoTextProps extends TypographyProps {
  translation: string;
}

export const InfoText: React.FC<InfoTextProps> = ({
  translation,
  ...attributes
}) => {
  const { t } = useTranslation();

  return (
    <Typography variant="body1" m={4} textAlign="center" {...attributes}>
      {t(translation)
        .split("\n")
        .map((s) => (
          <>
            {s}
            <br />
          </>
        ))}
    </Typography>
  );
};
