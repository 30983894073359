import React, { useMemo, useRef, useState } from "react";
import {
  Card,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { downloadFile, numberToPrice } from "../../lib/helpers/utils";
import Loader from "../../lib/components/atomics/Loader";
import { getPath as getPathInvoiceId } from "./invoices.$invoiceType.$invoiceId";
import dayjs from "dayjs";
import SubpageWrapper from "../../lib/components/atomics/SubpageWrapper";
import { apiHooks, Schemas } from "../../lib/apis/norskGassnettApiHooks";
import LoadingButton from "../../lib/components/atomics/LoadingButton";
import MUIIcon from "../../lib/components/atomics/MUIIcon";
import StaticButton from "../../lib/components/atomics/StaticButton";
import LoadingIconButton from "../../lib/components/atomics/LoadingIconButton";
import { useGetInvoicePdf } from "../../lib/hooks/useGetInvoicePdf";
import { InfoText } from "../../lib/components/complex/InfoText";

export const PATH_SEGMENT = "faktura";
export const PATH = `/${PATH_SEGMENT}`;
export const getPath = () => PATH;

export const SALES_INVOICE = "faktura";
export const SALES_CREDIT_MEMO = "kreditnota";

export type SalesInvoiceT = typeof SALES_INVOICE;
export type SalesCreditMemoT = typeof SALES_CREDIT_MEMO;
export type InvoiceType = SalesInvoiceT | SalesCreditMemoT;

export type ExtendedSalesInvoice = Schemas["SalesInvoice"] & {
  invoiceType: SalesInvoiceT;
};
export type ExtendedSalesCreditMemo = Schemas["SalesCreditMemo"] & {
  invoiceType: SalesCreditMemoT;
};

export type CombinedInvoice = ExtendedSalesInvoice | ExtendedSalesCreditMemo;

interface InvoiceProps {
  invoice: CombinedInvoice;
}

const Invoice: React.FC<InvoiceProps> = ({ invoice }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const tinyButton = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const invoiceTypeString = invoice.invoiceType === SALES_INVOICE
    ? "invoice_name"
    : "credit_memo_name";
  const invoiceName = (invoice.number &&
    t(`invoice_details.${invoiceTypeString}`).replace(
      "{{invoice}}",
      invoice.number.toString(),
    )) ||
    t(`invoice_details.standard_${invoiceTypeString}`);

  const { mutateAsync, isPending, error } = useGetInvoicePdf();

  const borderLeftColor =
    invoice.invoiceType === SALES_INVOICE && invoice.status === "Open"
      ? theme.palette.error.main
      : invoice.invoiceType === SALES_CREDIT_MEMO
      ? theme.palette.warning.main
      : "transparent";

  const handleGetPdf = async () => {
    const pdfString = await mutateAsync(invoice);
    if (pdfString) {
      downloadFile({
        name: invoiceName,
        data: pdfString,
      });
    }
  };

  const linkProps = isXs
    ? {
      href: getPathInvoiceId(invoice.invoiceType, invoice.id),
      component: Link,
      underline: "none",
    }
    : null;
  return (
    <Card sx={{ borderRadius: 0, p: 0, width: "100%" }}>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        gap={3}
        sx={{
          borderLeft: `solid ${theme.spacing(1)}`,
          py: 2,
          px: 3,
          borderLeftColor,
        }}
      >
        <Stack {...linkProps}>
          <Typography variant="h4" fontSize="1.1rem">
            {t("invoice.due_date")}:{" "}
            {dayjs(invoice.dueDate).format("D MMM YYYY")}
          </Typography>
          <Typography variant="subtitle2" fontSize="1rem">
            {numberToPrice(
              invoice.totalAmountIncludingTax,
              invoice.currencyCode,
            )}
            {" • #"}
            {invoice.number}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          {tinyButton
            ? (
              <LoadingIconButton
                loading={isPending}
                title={invoiceName}
                onClick={handleGetPdf}
              >
                <MUIIcon name={error ? "file_download_off" : "download"} />
              </LoadingIconButton>
            )
            : (
              <LoadingButton
                square
                variant="outlined"
                startIcon={
                  <MUIIcon name={error ? "file_download_off" : "download"} />
                }
                loading={isPending}
                title={invoiceName}
                onClick={handleGetPdf}
              >
                {t("invoice.download")}
              </LoadingButton>
            )}
          <StaticButton
            // variant="outlined"
            href={getPathInvoiceId(invoice.invoiceType, invoice.id)}
            sx={{ display: { xs: "none", sm: "inline_flex" } }}
          >
            {t("invoice.show_invoice")}
          </StaticButton>
        </Stack>
      </Stack>
    </Card>
  );
};

interface InvoiceListProps {
  title: string;
  placeholder: string;
  children: React.ReactNode[];
}

const InvoiceList = ({ title, placeholder, children }: InvoiceListProps) => (
  <>
    <Typography variant="h4">{title}</Typography>
    <Stack width="100%" sx={{ pt: 4, mb: 5 }} gap={2}>
      {children?.length ? children : placeholder && (
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{ opacity: 0.4 }}
        >
          {placeholder}
        </Typography>
      )}
    </Stack>
  </>
);

export const getDefaultInvoiceDateFrom = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return date.toISOString().split("T")[0];
};

const Invoices: React.FC = () => {
  const { t } = useTranslation();

  const [invoiceDateFrom, setInvoiceDateFrom] = useState<string>(
    getDefaultInvoiceDateFrom(),
  );
  const [prevLength, setPrevLength] = useState(-Infinity);

  // TODO: If an open unpaid invoice is theoretically older than 1 year, it wont show

  const invoices = apiHooks.useQuery("get", "/v1/invoice", {
    params: { query: { invoiceDateFrom } },
  });

  const { closedInvoices = [], openInvoices = [] } = useMemo(() => {
    if (!invoices.data) return {};

    const salesInvoices = invoices.data.data.salesInvoices
      .map((invoice) => ({
        ...invoice,
        invoiceType: SALES_INVOICE,
      }))
      .sort((a, b) =>
        b.dueDate.localeCompare(a.dueDate)
      ) as ExtendedSalesInvoice[];
    const salesCreditMemos = invoices.data.data.salesCreditMemos
      .map((invoice) => ({
        ...invoice,
        invoiceType: SALES_CREDIT_MEMO,
      }))
      .sort((a, b) =>
        b.dueDate.localeCompare(a.dueDate)
      ) as ExtendedSalesCreditMemo[];

    const openInvoices: CombinedInvoice[] = [];
    const paidInvoices: CombinedInvoice[] = [];

    for (const invoice of salesInvoices) {
      if (invoice.status === "Open") openInvoices.push(invoice);
      else paidInvoices.push(invoice);
    }

    const combinedClosedInvoices = [...salesCreditMemos, ...paidInvoices].sort(
      (a, b) => b.dueDate.localeCompare(a.dueDate),
    );

    return { closedInvoices: combinedClosedInvoices, openInvoices };
  }, [invoices.data]);

  const invoiceRef = useRef({
    closedInvoices: [] as CombinedInvoice[],
    openInvoices: [] as CombinedInvoice[],
  });
  if (invoices.data) {
    invoiceRef.current.closedInvoices = closedInvoices;
    invoiceRef.current.openInvoices = openInvoices;
  }

  const totalShownInvoices = openInvoices.length + closedInvoices.length;
  const disableFetch = prevLength === totalShownInvoices;

  const incrementScope = () => {
    const y = parseInt(invoiceDateFrom.substring(0, 4));
    const newDate = y - 1 + invoiceDateFrom.substring(4);

    const newLength = Math.max(totalShownInvoices, prevLength);
    setPrevLength(newLength);
    setInvoiceDateFrom(newDate);
  };

  return (
    <SubpageWrapper title={t("invoice.title")}>
      <InfoText translation="invoice.technical_difficulties" />
      <InvoiceList
        title={t("invoice.unpaid_invoices")}
        placeholder={t("invoice.no_unpaid_invoices")}
      >
        {invoiceRef.current.openInvoices.map((invoice) => (
          <Invoice invoice={invoice} key={invoice.id} />
        ))}
      </InvoiceList>
      <InvoiceList
        title={t("invoice.paid_invoices")}
        placeholder={t("invoice.no_paid_invoices")}
      >
        {invoiceRef.current.closedInvoices.map((invoice) => (
          <Invoice invoice={invoice} key={invoice.id} />
        ))}
        {invoices.isLoading && <Loader />}
      </InvoiceList>
      <LoadingButton
        variant="outlined"
        disabled={disableFetch}
        loading={invoices.isLoading}
        endIcon={
          <MUIIcon name={disableFetch ? "event_available" : "event_repeat"} />
        }
        onClick={incrementScope}
      >
        {t(`invoice.${disableFetch ? "no" : "show"}_more_invoices`)}
      </LoadingButton>
    </SubpageWrapper>
  );
};
export default Invoices;
